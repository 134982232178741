<template>
    <div class="seongdong_container seongdong_bg_img" :id="getCssId">
        <loading-blur v-if="$store.getters['seongdongplayer/getLoadingStatus']"
                      :curUpdate="$store.getters['seongdongplayer/getcurUpdate']" />
        <loading-full v-if="$store.getters['player/getIsLoading']" :curUpdate="curUpdateText" />
        <!--===================== 눈 =====================-->
        <particles-bg v-if="weatherInfo.weatherGb === 'snow'" id="particles-js" type="custom" :config="config" :bg="true" />
        <!--===================== 비 =====================-->
        <canvas width='1280' height='800' v-show="weatherInfo.weatherGb === 'rain'" id="raincanvas"></canvas>
        <ul class="seondong_aplayz_header">
            <li class="sendong_aplayz_logo">
                <img class="cursor" src="/media/img/logo_white.svg" width="120" height="40" alt="">
            </li>
        </ul>

        <!--===================== 맑음 =====================-->
        <!-- 아침,낮,저녁 - 맑음,눈-->
        <img class="weatherlayout_cloud weatherlayout_cloud1" src="/media/img/morning_cloud2.png" alt="cloud1">

        <seongdong-player />
    </div>
</template>
<script>
import SeongDongLoadingFull from '@/components/seongdongLibary/SeongDongLoadingFull.vue';
import axios from "axios";
import { createMyChannel } from "@/assets/js/webcast";
import { checkLike } from '@/service/api/playerApi';
import { rainAnimation } from "@/assets/js/rainAnimation";
import { ParticlesBg } from "particles-bg-vue";
export default {
  name: 'AplayzFrontendSeongdongLibaryView',
  components: {
    ParticlesBg,
    'loading-full': SeongDongLoadingFull,
    'loading-blur': () => import(`@/components/seongdongLibary/SeongDongLoadingBlur.vue`),
    'seongdong-player': () => import('@/components/seongdongLibary/SeongDongPlayer.vue')
  },
  data () {
    return {
      loadingStatus: false,
      storeHlsWatch: () => { },
      storeChannelInfoWatch: () => { },
      storeChannelUrlWatch: () => { },
      musicInfoInterval: -1,
      lon: null,
      lat: null,
      weatherInfo: {
        id: 'morning_time',
        cssid: 'afternoon_time'
      },
      spaceInfo: {},
      curUpdateText: '',
      infoInterval: -1,
      widthSize: 0,
      config: {
        num: 1,
        rps: -1,
        radius: [15, 15],
        life: [10, 10],
        v: 0.1,
        tha: [150, 200],
        alpha: [0.3, 1],
        scale: [0.1, 0.4],
        position: "all",
        cross: "dead",
        random: 10,
        color: "#ffffff",
        g: 0.5
      },
      selectRecommendInterval: -1
    };
  },
  async created () {
    this.storeChannelInfoWatch = this.$store.watch(() => this.$store.getters["player/getChannelInfo"], (val, oldval) => {
      const { spaceNm: curSpaceName } = val;
      const { spaceNm: oldSpaceName } = oldval || { spaceNm: '' };
      if (curSpaceName !== oldSpaceName) {
        clearInterval(this.$store.state.musicInfoInterval);
        this.musicInfoSetting();
        this.getChannelMusicInfo();
        if (val) {
          const { channelType } = val;
          if (channelType === 'T') {
            const intervalId = this.originalChannelInterval();
            this.$store.commit("player/setOriginalChannelInterval", intervalId);
          } else {
            clearInterval(this.$store.getters['player/getOriginalChannelInterVal']);
          }
        }
      }
    });
    this.storeChannelUrlWatch = this.$store.watch(() => this.$store.getters.gettersChannelUrl, async (val, oldVal) => {
      if (val !== oldVal) {
        clearInterval(this.$store.state.musicInfoInterval);
        this.musicInfoSetting();
        if (Object.keys(this.$route.params).length > 0) {
          this.$store.commit("setPlay", true);
        }

        if (this.$store.state.isPlay === '' || this.$store.state.isPlay === true) {
          try {
            this.$store.commit("setPlay", true);

            await this.retryChannelUrl(0);
          } catch (error) {
            console.log("error : ", error);
          } finally {
            this.loadingStatus = false;
            // this.curUpdateText = '';
            if (this.$store.getters.getCarCurationModal) {
              this.$VideoPlayer.onPause();
            }
          }
        }
      }
    });

    if (this.$store.state.channelId === '') {
      this.$store.commit("player/setIsLoading", true);
    }
    if (this.$store.getters.gettersChannelMusicInfo) {
      this.checkLike();
    }
    if (this.$store.state.channelId !== '') {
      this.selectRecommendedChannels();
      try {
        await this.getChannelInfo();
      } catch (error) {
        await this.retryChannelInfo(0);
      }
    } else {
      const { params } = this.$route;
      if (Object.keys(params).length === 0) {
        await createMyChannel();
      }
    }

    await this.getCurationInfo(); // 큐레이션 정보
    this.getPlayerInfo();
    const infoInterval = setInterval(() => { // 플레이어 정보(날씨, 시간) 5분 단위로 가져오기
      const today = new Date();
      console.log("info : " + today + " : " + this.infoInterval);
      this.getPlayerInfo();
    }, 1000 * 5 * 60);
    if (this.selectRecommendInterval) clearInterval(this.selectRecommendInterval);
    this.selectRecommendInterval = setInterval(this.selectRecommendedChannels, 1000 * 5);
    this.infoInterval = infoInterval;

    this.timeOutId = setTimeout(() => {
      clearInterval(this.selectRecommendInterval);
      this.selectRecommendInterval = -1;
      if (this.$store.state.channelId !== '') this.selectRecommendInterval = setInterval(this.selectRecommendedChannels, 1000 * 60);
    }, 1000 * 60);
  },

  async mounted () {
    const { params } = this.$route;
    if (Object.keys(params).length > 0) {
      const { spaceState, curUpdate } = params;
      if (spaceState === 'changeSpace' || spaceState === 'newSpace') {
        this.curUpdateText = 'updated';
        await this.getCurationInfo();
        this.$store.commit("setPlay", true);
        await createMyChannel();
      } else if (curUpdate === 'updated') {
        await createMyChannel();
      }
    }
    window.addEventListener('resize', this.handleResize);
    this.changeWeather();
  },

  methods: {
    originalChannelInterval () {
      const standard = 1000 * 120;
      return setInterval(() => {
        fetch(this.$store.getters["player/getOriginalUrl"]);
      }, standard);
    },
    handleResize () {
      this.widthSize = window.innerWidth;
    },
    changeWeather () {
      const { weatherGb } = this.weatherInfo;
      if (weatherGb === 'rain') {
        this.$nextTick(() => {
          rainAnimation.init(1280, 800);
          rainAnimation.animate();
          window.addEventListener('resize', rainAnimation.resize.bind(rainAnimation));
        });
      }
    },
    getWeatherId (times = '낮', weather = '1') {
      let weatherId = '';
      if ((times === '낮' && weather === '1') || (times === '낮' && weather === '4')) {
        weatherId = 'afternoon';
      } else if (times === '낮' && weather === '2') {
        weatherId = 'afternoonCloud';
      } else if (times === '낮' && weather === '3') {
        weatherId = 'afternoonRain';
      } else if ((times === '저녁' && weather === '1') || (times === '저녁' && weather === '4')) {
        weatherId = 'dinner';
      } else if (times === '저녁' && weather === '2') {
        weatherId = 'dinnerCloud';
      } else if (times === '저녁' && weather === '3') {
        weatherId = 'dinnerRain';
      } else if ((times === '아침' && weather === '1') || (times === '아침' && weather === '4')) {
        weatherId = 'mornig';
      } else if (times === '아침' && weather === '2') {
        weatherId = 'mornigCloud';
      } else if (times === '아침' && weather === '3') {
        weatherId = 'mornigRain';
      } else if ((times === '밤' && weather === '1') || (times === '밤' && weather === '4')) {
        weatherId = 'night';
      } else if (times === '밤' && weather === '2') {
        weatherId = 'nightCloud';
      } else if (times === '밤' && weather === '3') {
        weatherId = 'nightRain';
      }

      return weatherId;
    },
    getPlayerInfo () {
      this.$store.commit('setPreInfo', this.$store.state.currentInfo);
      const spaceId = this.$cookies.get("spaceId");
      const aToken = this.$cookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      let lat = 37.566826;
      let lon = 126.9786567;
      if (this.lat != null) { // 위도 경도값을 못가져왔을때 서울 중심 위치로 고정
        lat = this.lat;
        lon = this.lon;
      }
      this.getWeathreTimeInfo(spaceId, lon, lat, headers);
    },
    getWeathreTimeInfo (spaceId, lon, lat, headers) {
      const weather = this.$store.state.selectWeatherAndTime.weather;
      const time = this.$store.state.selectWeatherAndTime.times;
      axios
        .post("/api/player/getPlayerInfo ", { spaceId, lon, lat, weather, time }, { headers })
        .then((res) => {
          // Temp
          // res.data.result.currentTimes = '낮';
          // res.data.result.currentWeather = '2';
          this.spaceInfo = res.data.result;
          this.$store.commit("setSpaceNm", this.spaceInfo.spaceNm);
          const currentInfo = {
            weather: this.spaceInfo.currentWeather,
            times: this.spaceInfo.currentTimes
          };
          this.weatherInfo = {
            id: this.getWeatherId(this.spaceInfo.currentTimes, this.spaceInfo.currentWeather),
            currentTimes: this.spaceInfo.currentTimes,
            currentWeather: this.spaceInfo.currentWeather,
            weatherGb: this.getWeather(this.spaceInfo.currentWeather)
          };
          this.$store.commit("player/setWeatherInfo", this.weatherInfo);
          this.$store.commit("setCurrentInfo", currentInfo);
        });
    },
    getWeather (weatherValue = '1') {
      let weather = 'sunny';
      if (weatherValue === '1') {
        weather = 'sunny';
      } else if (weatherValue === '2') {
        weather = 'cloud';
      } else if (weatherValue === '3') {
        weather = 'rain';
      } else if (weatherValue === '4') {
        weather = 'snow';
      }
      return weather;
    },
    async selectRecommendedChannels () {
      const spaceId = this.$cookies.get("spaceId");
      const aToken = this.$cookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      await axios
        .post("/api/player/selectRecommendedChannels", { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.$store.commit("player/setRecomList", res.data.result);
          }
        });
    },
    getChannelInfo () {
      const channelId = this.$store.state.channelId;
      const aToken = this.$cookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      axios
        .post("/api/player/getChannelInfo", { channelId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.$store.commit("player/setChannelInfo", result);
          }
        })
        .catch(e => { console.log(e); })
        .finally(() => {

          // this.isLoading = false;
        });
    },
    async getCurationInfo () {
      const spaceId = this.$cookies.get("spaceId");
      const aToken = this.$cookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };
      await axios
        .post("/api/profile/getCurationInfo", { spaceId }, { headers })
        .then((res) => {
          const result = res.data.result;
          if (res.data.resultCd === '0000') {
            result.genre = result.genre.filter((genre) => genre !== '인디음악');
            setTimeout(() => {
              this.$store.commit("player/setCurationInfo", result);
            }, 500);
          }
        });
    },
    musicInfoSetting () {
      if (this.musicInfoInterval) clearInterval(this.musicInfoInterval);
      this.musicInfoInterval = setInterval(() => { // 채널 음원, 추천 채널 정보 5초 단위로 가져오기
        if (this.$store.state.channelId !== '') this.getChannelMusicInfo(); // 채널 음원 정보
      }, 1000 * 5);
      this.$store.commit("setMusicInfoInterval", this.musicInfoInterval);
    },
    async getChannelMusicInfo () {
      const channelId = this.$store.state.channelId;
      const aToken = this.$cookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      await axios
        .post("/api/player/getChannelMusicInfo", { channelId }, { headers })
        .then(async res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (this.$store.state.channelMusicInfo == null || result.musicNm !== this.$store.state.channelMusicInfo.musicNm) {
              // 앨범 커버가 empty 이미지면 이전 이미지를 넣어줌
              if (this.$store.state.channelMusicInfo != null && result.musicId === this.$store.state.channelMusicInfo.nextMusicId && this.$store.state.channelMusicInfo.nextAlbumImg.indexOf('empty') > -1) result.albumImg = this.$store.state.channelMusicInfo.nextAlbumImg;
              if (this.$store.state.channelMusicInfo != null && result.prevMusicId === this.$store.state.channelMusicInfo.musicId && this.$store.state.channelMusicInfo.albumImg.indexOf('empty') > -1) result.prevAlbumImg = this.$store.state.channelMusicInfo.albumImg;
              this.$store.commit('setChannelMusicInfo', result);
              this.checkLike();
            }
          }
        });
    },
    setTitle () { },
    checkLike () {
      const spaceId = this.$cookies.get("spaceId");
      const userId = this.$cookies.get("userId");
      const musicPath = this.$store.state.channelMusicInfo.musicId;
      if (spaceId && this.$store.state.channelMusicInfo) {
        checkLike(spaceId, userId, musicPath)
          .then(res => {
            const { resultCd, result } = res.data;
            if (resultCd === '0000') {
              const likeInfo = {
                like: result.like,
                unlike: result.unlike
              };
              this.$store.commit("setLikeInfo", likeInfo);
            }
          })
          .catch((err) => {
            console.log("/api/player/selectMusicLikeUnlike  : ", err);
          });
      }
    },
    async retryChannelUrl (n) {
      return await new Promise((resolve, reject) => {
        if (n < 30) {
          setTimeout(async () => {
            return await fetch(this.$store.state.channelUrl)
              .then(() => {
                this.$VideoPlayer.playerMusic(this.$store.state.channelUrl);
                this.$VideoPlayer.startLoad(-1);
                this.$VideoPlayer.onPlay()
                  .catch(err => {
                    this.$store.commit("setPlay", false);

                    alert("재생버튼을 클릭해주세요.");
                    console.log("err : ", err.message);
                    console.log("재생 오류 ");
                  })
                  .finally(async () => {
                    if (this.loadingStatus) {
                      this.loadingStatus = false;
                    }
                    await this.getChannelInfo();
                    await this.getCurationInfo(); // 큐레이션 정보
                    if (this.$store.getters['player/getIsLoading'] && this.$store.state.channelId !== '') {
                      this.$store.commit("player/setIsLoading", false);
                      this.curUpdateText = '';
                    }
                    await this.getChannelMusicInfo();
                    this.$store.commit("seongdongplayer/setLoadingStatus", false);
                    this.$store.commit("seongdongplayer/setCurUpdate", "");
                  });
                resolve("success");
              })
              .catch(_ => {
                this.retryChannelUrl(n + 1);
              })
              .finally(() => {
                this.loadingStatus = false;
              });
          }, 1500);
        } else {
          reject(new Error("fail"));
        }
      });
    }
  },
  computed: {
    getCssId () {
      const { currentTimes, currentWeather } = this.weatherInfo;
      if (currentTimes === '아침' && parseInt(currentWeather) === 1) {
        return 'morning_time';
      } else if (currentTimes === '아침' && parseInt(currentWeather) === 2) {
        return 'morning_cloud_time';
      } else if (currentTimes === '아침' && parseInt(currentWeather) === 3) {
        return 'morning_rain_time';
      } else if (currentTimes === '아침' && parseInt(currentWeather) === 4) {
        return 'morning_snow_time';
      } else if (currentTimes === '낮' && parseInt(currentWeather) === 1) {
        return 'afternoon_time';
      } else if (currentTimes === '낮' && parseInt(currentWeather) === 2) {
        return 'afternoon_cloud_time';
      } else if (currentTimes === '낮' && parseInt(currentWeather) === 3) {
        return 'afternoon_rain_time';
      } else if (currentTimes === '낮' && parseInt(currentWeather) === 4) {
        return 'afternoon_snow_time';
      } else if (currentTimes === '저녁' && parseInt(currentWeather) === 1) {
        return 'dinner_time';
      } else if (currentTimes === '저녁' && parseInt(currentWeather) === 2) {
        return 'dinner_cloud_time';
      } else if (currentTimes === '저녁' && parseInt(currentWeather) === 3) {
        return 'dinner_rain_time';
      } else if (currentTimes === '저녁' && parseInt(currentWeather) === 4) {
        return 'dinner_snow_time';
      } else if (currentTimes === '밤' && parseInt(currentWeather) === 1) {
        return 'night_time';
      } else if (currentTimes === '밤' && parseInt(currentWeather) === 2) {
        return 'night_cloud_time';
      } else if (currentTimes === '밤' && parseInt(currentWeather) === 3) {
        return 'night_rain_time';
      } else if (currentTimes === '밤' && parseInt(currentWeather) === 4) {
        return 'night_snow_time';
      }
      return 'afternoon_time';
    }
  },
  destroyed () {
    this.$store.commit("player/setIsLoading", false);
    this.storeChannelInfoWatch();
    this.storeChannelUrlWatch();
    clearInterval(this.infoInterval);
    clearInterval(this.selectRecommendInterval);
  },
  watch: {
    weatherInfo: {
      deep: true,
      handler () {
        this.changeWeather();
      }
    }
  }
};
</script>
<style>
.seongdong_container #particles-js {
  z-index: 2 !important;
}
</style>
<style src="@/assets/css/content2.css"></style>
<style scoped src="@/assets/css/seongdonglibary/seongdonglibaryview.css"></style>
